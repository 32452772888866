import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import loader from './loader';
import statistics from './statistics';
import classifiers from './classifiers';

const store = new Vuex.Store({
  modules: {
    loader,
    statistics,
    classifiers
  }
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  let storeData = JSON.parse(JSON.stringify(store.state));
  initialStateCopy['classifiers'] = storeData['classifiers'];
  initialStateCopy['auth']['is_auth'] = false;
  initialStateCopy['auth']['user'] = null;
  initialStateCopy['auth']['role_name'] = "";
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}