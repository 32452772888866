export default {
    "Информация о резидентах": "Rezidentlar to‘g‘risida ma'lumot",
    "2022 г.": "2022 y.",
    "Весь год": "Yil",
    "1-квартал": "1-chorak",
    "2-квартал": "2-chorak",
    "3-квартал": "3-chorak",
    "4-квартал": "4-chorak",
    "quarter_1": "1-chorak",
    "quarter_2": "2-chorak",
    "quarter_3": "3-chorak",
    "quarter_4": "4-chorak",
    "Все регионы": "Barcha viloyatlar",
    "Республика Каракалпакстан": "Qoraqalpog‘iston Respublikasi",
    "Бухарская область": "Buxoro viloyati",
    "Самаркандская область": "Samarqand viloyati",
    "Навоийская область": "Navoiy viloyati",
    "Андижанская область": "Andijon viloyati",
    "Ферганская область": "Farg‘ona viloyati",
    "Сурхандарьинская область": "Surxondaryo viloyati",
    "Сырдарьинская область": "Sirdaryo viloyati",
    "Хорезмская область": "Xorazm viloyati",
    "Ташкентская область": "Toshkent viloyati",
    "Кашкадарьинская область": "Qashqadaryo viloyati",
    "Джизакская область": "Jizzax viloyati",
    "Наманганская область": "Namangan viloyati",
    "город Ташкент": "Toshkent shahri",
    "Всего резидентов:": "Jami rezidentlar:",
    "Экспортеры:": "Eksportyorlar:",
    "Резиденты с участием иностранного капитала:": "Chet el kapitali ishtirokidagi rezidentlar:",
    "По форме собственности:": "Mulkchilik shakli bo'yicha:",
    "Объём оказанных услуг:": "Ko'rsatilgan xizmatlar hajmi:",
    "Объём экспорта:": "Eksport hajmi:",
    "Прогноз:": "Reja:",
    "тыс. сум": "ming so'm",
    "Предоставлена:": "Taqdim etilgan:",
    "Услуга “Virtual office”": "“Virtual office” xizmati",
    "Услуга “One Stop Shop”": "“One Stop Shop” xizmati",
    "Отказ в предоставлении статуса резидента": "Rezident maqomi berilishida rad etilganlar",
    "Получившие статус резидента": "Rezident maqomini olganlar",
    "Лишено статуса резидента": "Rezident maqomi bekor qilinganlar",
    "Образовательные компании:": "Ta'lim kompaniyalari",
    "Количество сотрудников:": "Xodimlar soni",
    "раза": "marta",
    "Регионы": "Viloyatlar",
    "Прочие 7 стран по": "Qolgan 7 ta davlat",
    "г.": "y.",
    "Республика Каракалпакстан": "Qoraqalpog‘iston Respublikasi",
    "Бухарская область": "Buxoro viloyati",
    "Самаркандская область": "Samarqand viloyati",
    "Навоийская область": "Navoiy viloyati",
    "Андижанская область": "Andijon viloyati",
    "Ферганская область": "Farg‘ona viloyati",
    "Сурхандарьинская область": "Surxondaryo viloyati",
    "Сырдарьинская область": "Sirdaryo viloyati",
    "Хорезмская область": "Xorazm viloyati",
    "Ташкентская область": "Toshkent viloyati",
    "Кашкадарьинская область": "Qashqadaryo viloyati",
    "Джизакская область": "Jizzax viloyati",
    "Наманганская область": "Namangan viloyati",
    "город Ташкент": "Toshkent shahri",
    "Список стран экспортеров услуг резидентов IT Park": "IT Park rezidentlari xizmatlarini eksport qiluvchi mamlakatlar ro'yxati",
    "Россия": "Rossiya",
    "Беларусь": "Belarus",
    "Казахстан": "Qozog'iston",
    "США": "AQSH",
    "Южная Корея": "Janubiy Koreya",
    "Великобритания": "Buyuk Britaniya",
    "Германия": "Germaniya",
    "Нидерланды": "Niderlandiya",
    "ОАЭ": "BAA",
    "Швеция": "Shvetsiya",
    "Литва": "Litva",
    "Китай": "Xitoy",
    "Эстония": "Estoniya",
    "Латвия": "Latviya",
    "Израиль": "Isroil",
    "Кипр": "Kipr",
    "Новая Зеландия": "Yangi Zelandiya",
    "Ирландия": "Irlandiya",
    "Франция": "Fransiya",
    "Гонконг": "Gonkong",
    "Канада": "Kanada",
    "Австрия": "Avstriya",
    "Италия": "Italiya",
    "Бельгия": "Belgiya",
    "Сингапур": "Singapur",
    "Швейцария": "Shveytsariya",
    "Венгрия": "Vengriya",
    "Камбоджа": "Kambodja",
    "Грузия": "Gruziya",
    "Румыния": "Ruminiya",
    "Индия": "Hindiston",
    "Азербайджан": "Ozarbayjon",
    "Финляндия": "Finlyandiya",
    "Малайзия": "Malayziya",
    "Таджикистан": "Tojikiston",
    "Чехия": "Chexiya",
    "Турция": "Turkiya",
    "Другие страны": "Boshqa mamlakatlar",
    "OOO": "MChJ",
    "ИП": "ChK",
    "ЧП": "XK",
    "ГУП": "DUK",
    "СП": "QK",
    "ННО": "NNT",
    "НОУ": "NTM",
    "АО": "AJ",
    "ДП": "ShK",
    "УП": "UK",
    "долл. США": "AQSh doll.",
    "Показать все": "Barchasini ko'rsatish",
    "за year год": "{year} yil uchun",
    "developers_count": "Dasturchilar",
    "foreign_developers_count": "Chet elliklar",
    "teachers_count": "O'qituvchilar",
    "foreign_teachers_count": "Xalqaro sertifikatga ega o'qituvchilar",
    "Данные за quarter квартал year года": "{year}-yil {quarter}-chorak maʼlumotlari",
    "Данные за year год": "{year}-yil maʼlumotlari",
    "count страны": "{count} ta davlat",
    "Показать всех резидентов": "Barcha rezidentlarni ko'rsatish",
    "Топ 20 компаний": "TOP 20 ta kompaniya",
    "Форма собственности": "Mulkchilik shakli",
    "Наименование компании": "Kompaniya nomi",
    "Регион": "Hudud",
    "Страна учредитель": "Ta'sischi davlat",
    "Дата регистрации": "Ro'yxatga olingan sana",
    "Направление деятельности": "Yo'nalishi",
    "Количество сотрудников": "Xodimlar soni",
    "Всего": "Jami",
    "Программисты": "Dasturchilar",
    "Иностранцы": "Chet elliklar",
    "Учителя": "O'qituvchilar",
    "Учителя с межд. сертификатом": "Xalqaro sertifikatga ega o'qituvchilar",
    "Направление": "Yo'nalish",
    "Все направление": "Barcha yo'nalishlar",
    "Год": "Yil",
    "Период": "Davr",
    "Всего резидентов": "Jami rezidentlar",
    "Объем оказанных услуг": "Ko'rsatilgan xizmatlar hajmi",
    "млрд": "mlrd",
    "сум": "so'm",
    "Объем экспорта": "Eksport hajmi",
    "млн": "mln",
    "USD": "USD",
    "По направлению деятельности": "Yo‘nalishlar bo‘yicha",
    "По форме собственности": "Mulkchilik shakli bo'yicha",
    "Тип организаций": "Tashkilot turi",
    "за весь период": "butun davr uchun",
    "Показать все страны-экспортеры": "Barcha eksport qiluvchi davlatlarni ko'rsatish",
    "Резиденты с участием иностранного капитала": "Chet el kapitali ishtirokidagi rezidentlar",
    "Выход": "Chiqish",
    "Тип компании": "Kompaniya turi",
    "Процентное соотношение": "Foiz ulushi",
    "Экспортеры": "Eksportyorlar",
    "Все": "Barchasi",
    "gu": "DM",
    "up": "UK",
    "dp": "ShK",
    "nno": "NNT",
    "sp_ooo": "QK MChJ",
    "chp": "XK",
    "ao": "AJ",
    "nou": "NTM",
    "sp": "OK",
    "gup": "DUK",
    "ooo": "MChJ",
    "ip_ooo": "ChK MChJ",
    "count компаний": "{count} kompaniya",
    "Статистические данные резидентов IT Park": "IT Park rezidentlarining statistik ma'lumotlari",
    "Войдите в личный кабинет": "Shaxsiy kabinetga kirish",
    "Логин": "Login",
    "Пароль": "Parol",
    "Войти": "Kirish",
    "Показать остальные регионы": "Boshqa hududlarni ko'rsatish",
    "компаний": "ta kompaniya",
    "Показать динамку роста": "O'sish dinamikasini ko'rsatish",
    "Показать разбивку по сотрудникам": "Xodimlarning taqsimlanishini ko‘rsatish",
    "Показать кварталы": "Choraklar boʻyicha koʻrsatish",
    "Показать меньше": "Kamroq koʻrsatish",
    "Перечень компаний экспортеров": "Eksport qiluvchi kompaniyalar ro'yxati",
    "Наименование компании": "Kompaniya nomi",
    "Резиденты в разрезе регионов": "Rezidentlar hududlar kesimida",
    "Финансовые показатели": "Moliyaviy ko'rsatkichlar",
    "Показать меньше": "Kamroq ko'rsatish",
    "Сумма 1% отчисления": "1% to'lov miqdori",
    "Задолженность по уплате": "To'lov bo'yicha qarzdorlik",
    "BI отдел": "BI bo'limi",
    "Уплачено": "To'langan",
    "Информационно-аналитический дашборд": "Axborot-tahliliy informatsion dashbordi",
    "Единый реестр резидентов": "Rezidentlarning yagona reestri",
    "Страны ЕС и Великобритания": "Buyuk Britaniya va YI davlatlari",
    "Северная Америка": "Shimoliy Amerika",
    "Страны APAC": "APAC davlatlari",
    "Страны MENA": "MENA davlatlari",
    "Страны СНГ": "MDH davlatlari",
    "Другие": "Boshqalar",
    "Сумма фонда развития экспорта": "Eksportni rivojlantirish uchun jamg'arma miqdori",
    "География экспорта услуг": "Eksport xizmatlarning geografiyasi",
    "Дата получения статуса резидента": "Rezident maqomini olgan sanasi",
    "Прочий доход": "Boshqa daromadlar",
    "Доход от услуг": "Xizmatlardan tushgan daromad",
    "Совокупный доход": "Jami daromad",
    "foreign_employees_count": "Chet elliklar"
}