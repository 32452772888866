import axios from 'axios';
import { getItem, setItem } from '@/utils/storage'
import store from '@/store';
import router from '@/router';
import { errorsAlert, netwokErrorAlert } from './requestAlerts';

const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, 
  timeout: 60000 // request timeout
});

service.interceptors.request.use(
  config => {
    loaderIncrease(config);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    loaderDecrease(response.config);
    const res = response.data;
    if (response.status !== 200) {
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res;
    }
  },
  error => {
    loaderDecrease(error.config);
    if (error.response && (error.response.status == 401)) {
      if(error.response && error.response['data'] && error.response['data']['message'] && error.response['data']['message'] != "Unauthenticated."){
        errorsAlert(error.response);
      }
    }else if(error.response){
      errorsAlert(error.response);
    }else{
      netwokErrorAlert(error);
    }
    return Promise.reject(error.response);
  }
);


const notLoader = [
  // '/classifiers/skills',
];
function loaderIncrease(config) {
  if (notLoader.indexOf(config['url']) < 0) {
    store.dispatch('loader/increase');
  }
}
function loaderDecrease(config) {
  if (notLoader.indexOf(config['url']) < 0) {
    store.dispatch('loader/decrease');
  }
}

export default service;
