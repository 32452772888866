import request from '@/utils/request';

export function residentsCount(params) {
  return request({
    url: '/statistics/residents-count',
    method: 'get',
    params
  });
}
export function residentsCountRegions(params) {
  return request({
    url: '/statistics/residents-count-regions',
    method: 'get',
    params
  });
}
export function residentsCountByRegions(params) {
  return request({
    url: '/statistics/resident-count-by-regions',
    method: 'get',
    params
  });
}
export function getSumAllIncomes(params) {
  return request({
    url: '/statistics/sum-all-incomes',
    method: 'get',
    params
  });
}
export function getSumExportIncomes(params) {
  return request({
    url: '/statistics/sum-export-incomes',
    method: 'get',
    params
  });
}
export function getEmployeesCount(params) {
  return request({
    url: '/statistics/employees-count',
    method: 'get',
    params
  });
}
export function getCompaniesByIndustries(params) {
  return request({
    url: '/statistics/companies-by-industries',
    method: 'get',
    params
  });
}
export function getResidentsByQuarter(params) {
  return request({
    url: '/statistics/residents-by-quarter',
    method: 'get',
    params
  });
}
export function getExportersByQuarter(params) {
  return request({
    url: '/statistics/exporters-by-quarter',
    method: 'get',
    params
  });
}
export function getEducationsByQuarter(params) {
  return request({
    url: '/statistics/educations-by-quarter',
    method: 'get',
    params
  });
}
export function getEmployeesByQuarter(params) {
  return request({
    url: '/statistics/employees-by-quarter',
    method: 'get',
    params
  });
}
export function getOwnershipByQuarter(params) {
  return request({
    url: '/statistics/ownership-by-quarter',
    method: 'get',
    params
  });
}
export function getIncomesByQuarter(params) {
  return request({
    url: '/statistics/incomes-by-quarter',
    method: 'get',
    params
  });
}
export function getExportIncomesByQuarter(params) {
  return request({
    url: '/statistics/export-incomes-by-quarter',
    method: 'get',
    params
  });
}
export function getResidentExportByCountries(params) {
  return request({
    url: '/statistics/resident-export-by-countries',
    method: 'get',
    params
  });
}
export function getResidentsMainIndicators(params) {
  return request({
    url: '/statistics/residents-main-indicators',
    method: 'get',
    params
  });
}



export function getResidentStatisticsResidentCount(params) {
  return request({
    url: '/resident-statistics/resident-count',
    method: 'get',
    params
  });
}
export function getResidentStatisticsResidentEmployeesCount(params) {
  return request({
    url: '/resident-statistics/resident-employees-count',
    method: 'get',
    params
  });
}
export function getResidentStatisticsResidentServiceCount(params) {
  return request({
    url: '/resident-statistics/resident-service-statistics',
    method: 'get',
    params
  });
}
export function getResidentStatisticsResidentExportStatistics(params) {
  return request({
    url: '/resident-statistics/resident-export-statistics',
    method: 'get',
    params
  });
}
export function getResidentStatisticsResidentCountByCategory(params) {
  return request({
    url: '/resident-statistics/resident-count-by-category',
    method: 'get',
    params
  });
}
export function getResidentStatisticsResidentCountByFormType(params) {
  return request({
    url: '/resident-statistics/resident-count-by-form-type',
    method: 'get',
    params
  });
}
export function getResidentStatisticsResidentExportCountries(params) {
  return request({
    url: '/resident-statistics/resident-export-zones',
    method: 'get',
    params
  });
}
export function getResidentStatisticsResidentForeignFounders(params) {
  return request({
    url: '/resident-statistics/resident-foreign-founders-for-public-dashboard',
    method: 'get',
    params
  });
}
export function getResidentStatisticsTopRegionalResidents(params) {
  return request({
    url: '/resident-statistics/top-residents-for-public-dashboard',
    method: 'get',
    params
  });
}

export function getExporterResidentsStatistics(params) {
  return request({
    url: '/resident-statistics/exporter-residents-statistics',
    method: 'get',
    params
  });
}

export function getFinancialIndicatorsStatistics(params) {
  return request({
    url: '/resident-statistics/financial-indicators',
    method: 'get',
    params
  });
}

export function getQuarterIncomesMetrics(params) {
  return request({
    url: '/resident-statistics/quarter-incomes-metrics',
    method: 'get',
    params
  });
}