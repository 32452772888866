import Vue from 'vue'
import VueRouter from 'vue-router'

import admin from './modules/admin'


Vue.use(VueRouter)

var modules = [];
modules = modules.concat(admin);

const routes = [
  {
    path: "/",
    name: "Main",
    redirect: { name: "Dashboard" },
    component: () =>
      import("@/layouts/Main"),
    children: modules
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(!to.meta['savePosition']){
      return { x: 0, y: 0 }
    }
  }
});

export default router
